@import "_base.scss";

footer {
  @extend .pt-3;
  @extend .pb-2;
  @extend .pt-xl-5;

  .contact {
    color: $primary;
    font-family: $headings-font-family;
    letter-spacing: 0.0625rem;

    .icon {
      display: inline-block;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      border: 1px solid $primary;
      padding-top: 0.55rem;
      padding-left: 0.75rem;
      vertical-align: middle;

      svg {
        width: 1rem;
        fill: $primary;
      }
    }
  }

  .copyright {
    font-family: $headings-font-family;
    color: $primary-faded;
    text-align: center;
    font-weight: 300;
  }
}
