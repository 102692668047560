@import "_base.scss";

.btn {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-size: $font-size-sm;
  font-family: $headings-font-family;
  cursor: pointer;
}

.btn-primary {
  color: #fff;

  &:hover {
    color: $primary;
    background: #fff;
    border-color: $primary;
  }
}

.btn-secondary {
  background: #fff;
  border-color: $primary;
  color: $primary-faded;

  &:hover {
    color: #fff;
    background: $primary;
    border-color: $primary;
  }
}
