@import "_base.scss";

.input-group-addon,
.form-control {
  padding-left: 1rem;
  padding-right: 1rem;
}

.input-group {
  .input-group-addon ~ .form-control {
    border-left: none;
  }
}
