@import "_base.scss";

.navbar-light {
  @extend .p-0;
  @extend .bg-white;

  .navbar-brand {
    @extend .px-3;
    @extend .py-2;
    @extend .px-xl-5;
    @extend .py-xl-4;
    margin: 0;

    img {
      width: 8rem;

      @include media-breakpoint-down(sm) {
        width: 5rem;
      }
    }
  }

  .navbar-text {
    @extend .px-3;
    margin: 0 auto;

    h1 {
      @extend .mb-0;
      @extend .text-uppercase;
      @extend .d-flex;
      @extend .align-items-center;
      font-weight: 300;

      img {
        @extend .d-inline-block;
        @extend .mr-3;
        width: 2rem;

        @include media-breakpoint-down(sm) {
          display: none !important;
        }
      }
    }
  }

  .navbar-collapse {
    @extend .bg-light;
    @extend .align-self-stretch;
    @extend .align-self-end;
    flex-grow: 0;
  }

  .navbar-nav {
    @extend .flex-row;
    @extend .justify-content-end;
    @extend .h-100;

    .nav-item {
      @extend .d-inline-block;
    }

    .nav-link {
      @extend .px-3;
      @extend .py-3;
      @extend .h-100;
      @extend .d-flex;
      @extend .align-items-center;

      @include media-breakpoint-up(lg) {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
      }

      &:hover {
        color: $link-color;
        background: darken($light, 4);
      }
    }

    .nav-link-language {
      background: $primary;
      color: #fff;

      &:hover {
        color: #fff;
        background: lighten($primary, 6);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .navbar-brand, .navbar-nav {
      margin: 0 auto;
    }
  }
}

.navbar-dark {
  @extend .py-3;
  @extend .px-xl-5;

  .navbar-toggler {
    border: none;
  }

  .nav-link {
    @extend .py-md-3;
  }
}

.nav-link {
  transition: $btn-transition;
}
