@import "_base.scss";

.content-login {
  background: url('../Images/Login-Background.jpg') center center no-repeat;
  background-size: cover;

  .container {
    background: $white;
  }
}

.login {
  .login-content {
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      content: '';
      width: 1rem;
      background: linear-gradient(to right, lighten($primary, 5) 0%,darken($primary, 5) 100%);
    }

    .icon {
      position: absolute;
      top: map-get($sizes, 5);
      left: -3rem;
      display: block;
      width: 6rem;
      height: 6rem;
      background: #fff;
      border-radius: 50%;
      padding: 1.5rem;
      box-shadow: rgba($dark, 0.6) 0 0 1rem;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}
