@import "_base.scss";

.alert {
  .close {
    color: $primary;
    opacity: 1;

    &:hover {
      opacity: 0.7;
    }
  }
}

.important-crest {
  position: relative;
  width: 8rem;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  .crest {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
