@import "_base.scss";

@include media-breakpoint-down(md) {
  h1 {
    font-size: $font-size-base * 2;
  }

  h2 {
    font-size: $font-size-base * 1.75;
  }

  h3 {
    font-size: $font-size-base * 1.5;
  }
}

h1 {
  @extend .text-info;
}

h6 {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.25rem;
}
